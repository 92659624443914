<template>
    <div class="lightbox_video_container">
        <div class="lightbox_video">
            <div class="lightbox_video__body" @click="close">
                <div class="lightbox_video__body__video">
                    <div class="lightbox_video__header__close" @click="close">
                        <IconSax name="close-circle" />
                    </div>
                    <VideoPlayerIframe :source='`<iframe width="560" height="${height}" src="${source}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                        ' />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VideoPlayerIframe from "@/components/VideoPlayerIframe.vue";

export default {
    components: {
        VideoPlayerIframe,
    },
    props: {
        source: {
            type: String,
            default: "",
        },
        height: {
            type: String,
            default: "480px",
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss" scoped>
.lightbox_video_container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .lightbox_video {
        width: 100%;
        height: 100%;
        padding: 1rem;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);




        .lightbox_video__body {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .lightbox_video__body__video {
                width: 65%;

                @media screen and (max-width: 768px) {
                    width: 100%;
                }

                .lightbox_video__header__close {
                    cursor: pointer;
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 1rem;

                    ::v-deep .svgCss {
                        svg {
                            width: 1rem;
                            height: 1rem;
                        }

                        svg path {
                            fill: #fff !important;
                        }
                    }
                }
            }
        }
    }
}
</style>